import React, { Fragment } from 'react'

function Footer() {
  return (
      <footer>
        <p>a Saigonese running around Vancouver</p>
      </footer>
  )
}

export default Footer