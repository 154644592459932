import React from 'react'

export default function Home() {
  return (
    <div>
      <p>Hi, anti-social Ashley is here :D</p>
      <p>🏗️ under construction 🧱</p>
    </div>
  )
}
